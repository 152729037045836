window.URL = window.URL || window.webkitURL; // Take care of vendor prefixes.

async function req(method, url, auth) {
  const headers = new Headers({
    Authorization: "Basic " + auth,
  });
  const options = {
    method: method,
    headers: headers,
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
  };
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    return response;
  } catch (err) {
    throw err;
  }
}

function get(url, auth) {
  return req("GET", url, auth);
}
function put(url, auth) {
  return req("PUT", url, auth);
}
function post(url, auth) {
  return req("POST", url, auth);
}

function getJSON(url, auth) {
  return get(url, auth).then((response) => response.json());
}

function insertImg(el, url, auth) {
  var r = new XMLHttpRequest();
  r.open("GET", url, true);
  r.setRequestHeader("Authorization", "Basic " + auth);
  r.responseType = "blob";
  r.onload = function (e) {
    if (this.status == 200) {
      var blob = this.response;

      var img = document.createElement("img");
      img.onload = function (e) {
        window.URL.revokeObjectURL(img.src);
      };
      img.src = window.URL.createObjectURL(blob);
      el.appendChild(img);
    }
  };
  r.send();
}

/*function preloadImage( url ) {
	return new Promise( function( ok ) {
		var img = new Image;
		img.onload = function( ){ ok( url ) };
		img.src = url;
	} );
}*/

function debounce(func, wait, immediate) {
  var timeout;
  return function debounce() {
    var context = this,
      args = arguments;
    var later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function readableFileSize(s) {
  const units = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  var i = 0;
  while (s >= 1024) {
    s /= 1024;
    ++i;
  }
  return s.toFixed(1) + " " + units[i];
}

function querystring(o) {
  var qs = [];
  for (var d in o) qs.push(d + "=" + encodeURIComponent(o[d]));
  return qs.join("&");
}
